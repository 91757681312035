import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Observable } from 'rxjs';
import { DataService } from '../../../app/services/data.service';
import { Data } from '../../../app/models/data';
import { Wedstrijd } from '../../models/wedstrijd';
import { BasePaginaDirective } from '../../directives/base/base-pagina.component';

@Component({
  selector: 'app-programma',
  templateUrl: './programma.component.html',
  styleUrls: ['./programma.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProgrammaComponent extends BasePaginaDirective implements OnInit {
  data$: Observable<Data>;

  constructor(
    private _dataService: DataService
  ) { super(); }

  ngOnInit() {
    this.data$ = this._dataService.data;
  }

  teSpelen(wedstrijden: [Wedstrijd]) {
    const teSpelenWedstrijden: Wedstrijd[] = [];

    wedstrijden?.forEach((wed: Wedstrijd) => {
      if (wed.score_thuis === 0 && wed.score_uit === 0 && this.isInToekomst(wed.datum)) {
        teSpelenWedstrijden.push(wed);
      }
    });
    return teSpelenWedstrijden;
  }

  isInToekomst(datum: string): boolean {
    // "2023-09-16 14:00:00.000"
    if (!datum || datum.length < 10) {
      return false;
    }
    const data = datum.substring(0, 10).split('-');
    const d = new Date(data[0] + '-' + data[1] + '-' + data[2]);
    const tijd = datum.substring(11, 16).split(':');
    if (tijd?.length === 2) {
      d.setHours(Number(tijd[0]));
      d.setMinutes(Number(tijd[1]));
    }
    const isValid = d && d.getMonth() + 1 === Number(data[1]) && d.getDate() === Number(data[2]) && d.getFullYear() === Number(data[0]);

    if (isValid) {
      return d >= new Date();
    } else {
      return false;
    }
  }

  geSpeeld(wedstrijden: [Wedstrijd]) {
    const gespeeldeWedstrijden: Wedstrijd[] = [];

    wedstrijden?.forEach((wed: Wedstrijd) => {
      if (wed.score_thuis > 0 || wed.score_uit > 0) {
        gespeeldeWedstrijden.push(wed);
      }
    });
    return gespeeldeWedstrijden.sort((a, b) => b.datum.localeCompare(a.datum));
  }

  scrollToElement(id: string): void {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
}
