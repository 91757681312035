import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './stappen/home/home.component';
import { ContactComponent } from './stappen/contact/contact.component';
import { TeamsComponent } from './stappen/teams/teams.component';
import { InfoComponent } from './stappen/info/info.component';
import { ProgrammaComponent } from './stappen/programma/programma.component';
import { NewsComponent } from './stappen/news/news.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DataService } from './services/data.service';
import { BbcComponent } from './stappen/bbc/bbc.component';
import { ClubComponent } from './stappen/club/club.component';
import { ContentpageComponent } from './directives/contentpage/contentpage.component';
import { ScrollTopComponent } from './directives/scrolltop/scrolltop.component';
import { GeboortedatumValidatorDirective } from './directives/validators/geboortedatum-validator.directive';
import { IbanValidatorDirective } from './directives/validators/iban-validator.directive';
import { SpeurtochtComponent } from './stappen/speurtocht/speurtocht.component';
import { ToolsComponent } from './stappen/tools/tools.component';
import { FaqComponent } from './stappen/faq/faq.component';

@NgModule({
    declarations: [
        AppComponent,
        BbcComponent,
        ClubComponent,
        HomeComponent,
        ContactComponent,
        TeamsComponent,
        ToolsComponent,
        InfoComponent,
        ProgrammaComponent,
        FaqComponent,
        NewsComponent,
        ContentpageComponent,
        ScrollTopComponent,
        GeboortedatumValidatorDirective,
        IbanValidatorDirective,
        SpeurtochtComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule
    ],
    providers: [DataService, provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
